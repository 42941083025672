* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'sans';
}

::-webkit-scrollbar {
  display: none;
}

@font-face {
  font-family: 'sans';
  src: url('./assets/fonts/px_grotesk_light.ttf');
}

@font-face {
  font-family: 'mono';
  src: url('./assets/fonts/ibm_plex_mono_light.ttf');
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}

input[type='color'] {
  -webkit-appearance: none;
  border: none;
  width: 15px;
  height: 15px;
  background: none;
  cursor: pointer;
}

input[type='color']:disabled {
  cursor: default;
}

input[type='color']::-webkit-color-swatch-wrapper {
  padding: 0;
}

input[type='color']::-webkit-color-swatch {
  border-radius: 50%;
  border: solid 0.1px rgb(175, 175, 175);
}

.outline_button:focus {
  box-shadow: 0px 0px 6px hsla(0, 0%, 0%, 0.5);
  outline: none;
}
